import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const backgroundImage = `${process.env.PUBLIC_URL}/background.png`;
  const eye1Image = `${process.env.PUBLIC_URL}/dexscreener.png`;
  const eye2Image = `${process.env.PUBLIC_URL}/pumplogo.png`;
  const noseImage = `${process.env.PUBLIC_URL}/raydium-removebg-preview.png`;
  const telegramImage = `${process.env.PUBLIC_URL}/telegramlogo.png`;
  const xImage = `${process.env.PUBLIC_URL}/xlogo.png`;
  const image3Image = `${process.env.PUBLIC_URL}/image3.jpg`;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileBackgroundImage = isMobile
  ? `${process.env.PUBLIC_URL}/background-mobile.png`
  : `${process.env.PUBLIC_URL}/background.png`;

  const handleCopy = (e) => {
    navigator.clipboard.writeText('Cusg5UcYBNRbcidZJWbAY2QF9MjTT2XDWQVuStEtpump');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="App">
<div
        className="background-container"
        style={{ backgroundImage: `url(${mobileBackgroundImage})` }}
      ></div>
      <div className="content">
        {/* <img src={logoImage} alt="Logo" style={{ borderRadius: '20px', width: '50%', height: '50%' }} />
         */}
         
         {/* <img src={image3Image} alt="Image3" className="image3" /> */}
        <div className="icons-bar">
          <a href="https://dexscreener.com/solana/Cusg5UcYBNRbcidZJWbAY2QF9MjTT2XDWQVuStEtpump" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={eye1Image} alt="Dexscreener Logo" className="icon-image" />
          </a>
          <a href="https://raydium.io/swap/?inputMint=sol&outputMint=Cusg5UcYBNRbcidZJWbAY2QF9MjTT2XDWQVuStEtpump" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={noseImage} alt="Nose Logo" className="icon-image" />
          </a>
          <a href="https://t.me/tortaonsol" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={telegramImage} alt="Telegram Logo" className="icon-image" />
          </a>
          <a href="https://x.com/tortaonsol/" className="icon" target="_blank" rel="noopener noreferrer">
            <img src={xImage} alt="X Logo" className="icon-image" />
          </a>
        </div>

        <footer className="App-footer" onClick={handleCopy}>
          <div className="image3-container">
           
            <p className="contract-address">CA: Cusg5UcYBNRbcidZJWbAY2QF9MjTT2XDWQVuStEtpump</p>
            {copied && <span className="copied-message">Copied!</span>}
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;